#container{
    /* background: var(--color-background);     */

    font-family: 'Raleway', sans-serif;
    color: var(--color-text);
    padding: 0px 5% 50px 5%;
}

.pagina-videos{
    display: flex;
    flex-direction: column;
}

.pagina-videos h1{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

.pagina-videos h2{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    margin-top: 2%;
    font-size: 25px;

    min-width: 90vw;
}

.pagina-videos #container-videos{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
}

.pagina-videos #container-videos iframe {
    border: none;
}

.pagina-videos #container-videos  .thumbnail {
    margin-top: 40px;
    margin-bottom: 40px;
    cursor: pointer;
    width: 320px;
    height: 180px;
}

.pagina-videos #container-videos .thumbnail p {
    margin-top: 5px;
    color: #171717;
    text-align: justify;
    font-family: 'Segoe UI', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.pagina-videos #container-videos .thumbnail iframe {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pagina-videos #faixa{
    width: 100%;
    height: 3px;
    background: #76CDA0;
    margin-top: 6%;
    margin-bottom: 2%;
}

@media (max-width: 1110px){
    .pagina-videos #container-videos{
        padding: 0;
        grid-template-columns: repeat(2, 1fr);
        align-content: center;
    }
}

@media (max-width: 775px){
    .pagina-videos{
        align-items: center;
    }
    .pagina-videos #container-videos{
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
    }

}
