@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;100&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;


    --color-background: #F9F9F9;
    --color-text: #252525;
    --color-button: #7A519F;
    --color-text-white: #FFFFFF;
    --color-nav:  #4BBF83;
}

body{
    background: var(--color-background); 
    overflow-x: hidden;
    position: relative;
}

body::after {
    content: '';
    background: url(/static/media/BG.292eb2e2.png) no-repeat;
    background-size: 80% auto;
    background-position: top left;
    opacity: .7;
    transform: rotate(8deg);
    position: fixed;
    top: -80px;
    left: -300px;
    z-index: -5;
    width: 100vw;
    height: max(100%, 100vh);
}

.loading {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1160px){
    body::after {
        background-size: 100% 100%;
    }
}

@media (max-width: 940px){
    body::after {
        background-size: 100% auto;
    }
}

@media (max-width: 880px){
    body::after {
        top: -40px;
        left: -120px;
    }
}

@media (max-width: 620px){
    body::after {
        top: -20px;
        left: -80px;
        background-size: 100vw auto;
    }
}

/* -- scroll -- */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: var(--color-nav);
    border-radius: 8px;
}



#nav-header{
    padding: 0px 100px 50px 100px;
    display: grid;
    grid-template-columns: 40% 60%;
}

#nav-header section a {
    text-decoration: none;
}

#nav-header nav{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-items: center;
    align-items: end;
    margin-bottom: 20px;
}
.active {
	border-bottom: 3px solid var(--color-nav);
	cursor: pointer;
}
#nav-header nav .nav-content {
    font-family: 'Raleway', sans-serif;
    text-decoration:none;
	color: #252525;
	cursor: pointer;  
	border-bottom: 3px solid transparent;
}

#nav-header nav .nav-content a {
    text-decoration: none;
    color: #252525;
}

#nav-header nav .nav-content:hover {
	transition: all 0.3s ease-in-out;
    border-bottom: 3px solid var(--color-nav);
}


#nav-header nav .nav-content:nth-child(3) {
    padding-top: 55px;
    text-align: center;
    width: 100%;
    height: 100%;
    background: var(--color-nav);
	color: #ffffff;
	padding-bottom: 3px;
}

#nav-header nav .nav-content:nth-child(3) a {
    color: #ffffff;
}

@media only screen and (max-width: 1024px){
    #nav-header{
        padding: 0px 80px 50px 80px;
        grid-template-columns: 35% 65%;
    }
}

@media only screen and (max-width: 768px){
    #nav-header{
        padding: 0px 50px 50px 50px;
        grid-template-columns: 30% 70%;
    }
}

@media only screen and (max-width: 687px){
    #nav-header{
        padding: 0px 10px 0px 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 50% 50%;
        grid-template-areas: "nav" 
                             "titulo";
    }
    #nav-header nav{
        grid-area: nav;
    }
    #nav-header section{
        grid-area: titulo;
        justify-self: center;
    }
    #nav-header h1{
        text-align: center;
    }
}

@media only screen and (max-width: 500px){

    #nav-header nav a{
        font-size: 14px;
    }
    #nav-header section h1{
        font-size: 28px;
    }
}

@media only screen and (max-width: 350px){

    #nav-header nav a{
        font-size: 12px;
    }
    #nav-header section h1{
        font-size: 26px;
    }
}

  
#main-logo{
    padding-top: 28px;
}

#main-logo h1{
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    color: #252525;
}

#main-logo h1:hover {
    -webkit-animation: mymove .8s ease-in-out;
            animation: mymove .8s ease-in-out;
}

#main-logo h1:last-child{
    margin-top: -5px;
    margin-left: 1em;
    color: var(--color-nav);  
}

@-webkit-keyframes mymove {
    0% {
        transform: translateX(0);
    }
    33% {
        transform: translateX(8px);
    }
    66% {
        transform: translateX(-8px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes mymove {
    0% {
        transform: translateX(0);
    }
    33% {
        transform: translateX(8px);
    }
    66% {
        transform: translateX(-8px);
    }
    100% {
        transform: translateX(0);
    }
}
#container-um{
    padding: 0px 100px 50px 100px;
}

.container-jogos{
   display: grid;
   width: 100%;

}

.container-jogos h1{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 35px;
    color: var( --color-text);
}

@media(max-width: 1024px){
    .container-jogos h1{
        font-size: 32px;
    }
}

@media(max-width: 768px){
    #container-um{
        padding: 0px 40px 50px 40px;
    } 
    .container-jogos h1{
        font-size: 28px;
    }


}

@media(max-width: 668px){
    #container-um{
        padding: 0px 40px 50px 40px;
    } 
    
}

@media(max-width: 580px){
    #container-um{
        padding: 0px 20px 50px 20px;
    } 
 
}



#jogos{
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 6fr;
}

#jogos img { 
    border-radius: 30px;
    width: 140px;
    height: 140px;
    object-fit: cover;
}

#jogos section{
    display: grid;
    align-self: end;
    justify-self: start;
    margin-left: 15px;
    width: 100%;
}

#jogos section h2{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    margin-bottom: 1%;
}

#jogos section p{
    color:#707070;
    font-family: 'Raleway', sans-serif;
}

#jogos .container-button{
    margin-top: 10px;
    text-decoration: none;

    padding: 7px 40px;

    justify-self: end;
   

    background: var(--color-button);
    color: var(--color-text-white);

    border: none;
    border-radius: 8px;
    
    font-family: 'Raleway', sans-serif;
    font-size: 14px ;   
    font-weight: 400;
    cursor: pointer;
}

#jogos .container-button:hover{
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

@media (max-width: 1024px){

    #jogos{
        grid-template-columns: 1fr 4fr;
    }
    
    #jogos img{ 
        width: 130px;
        height: auto;

        border-radius: 20px;
    }
}

@media(max-width: 768px){

    #jogos section{
        margin-left: 20px;
    }


    #jogos section h2{
        font-size:20px;
    }
    
    #jogos section p{
        font-size: 14px;
    }
    #jogos .container-button{
        font-size: 12px;
    }
}

@media(max-width: 668px){
  
    #jogos img{ 
        width: 125px;
    }
    
}

@media(max-width: 580px){
 
    #jogos img{ 
        width: 120px;
        height: 120px;

        border-radius: 15px;
    }

    #jogos{
        grid-template-columns: 30% 70%;
    }
  
}

@media(max-width: 430px){
  
    #jogos img{ 
        width: 100px;
        height: 100px;

        border-radius: 10px;
    }

    #jogos{
        grid-template-columns: 35% 65%;
    }

    #jogos section h2{
        font-size:18px;

    }
    
    #jogos section p{
        font-size: 12px;
    }
    #jogos .container-button{
        font-size: 12px;
        padding: 7px 15px;
        margin-right: 10px;
    }
  
}
#container-jogos-um {
  padding: 0px 100px 20px 100px;
}

.container-conteudo-jogos {
  display: grid;
  width: 100%;
  grid-template-columns: 55% 40%;
  grid-gap: 50px;
}

.container-conteudo-jogos .secao-texto {
  display: flex;
  flex-direction: row;
  height: 10vh;
  align-items: center;
  margin-bottom: 50px;
}

.container-conteudo-jogos section .secao-texto img {
  border-radius: 20px;
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
}

.container-conteudo-jogos section .secao-texto h1 {
  margin-left: 10px;
  justify-self: start;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 35px;
  color: var(--color-text);
}

.container-conteudo-jogos section p {
  color: var(--color-text);
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin: 5% 0;

  text-align: justify;
}

.container-conteudo-jogos section .cards {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.container-conteudo-jogos section .cards .secao-card {
  cursor: pointer;
  width: 85%;
  display: grid;
  justify-items: center;
  padding: 20px;

  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 18px;

  transition: all 0.5s;
}

.container-conteudo-jogos section .cards .secao-card h2 {
   text-align: center;
}

.container-conteudo-jogos section .cards .secao-card:hover {
  background: rgba(75, 191, 131, 0.4);
  border-color: #4bbf83;
  border-radius: 28px;
}

.container-conteudo-jogos section .cards .secao-card h2 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

.container-conteudo-jogos section .cards .secao-card p {
  padding-top: 6px;
  font-family: "Raleway", sans-serif;
  grid-template-columns: auto auto;
  grid-gap: 1px;
  cursor: pointer;
}

.container-conteudo-jogos section .button-plus {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  margin-top: 20px;
  cursor: pointer;

  font-family: "Raleway", sans-serif;
  color: #E8751D;
  font-size: 16px;

  background: #FFE0A3;
  padding: 3px 10px;
  border: 4px solid #E8751D;
  border-radius: 8px;
  transition: all 0.3s;
}

.container-conteudo-jogos section .button-plus:hover {
  background: #E8751D;;
  color: #FFE0A3;
}

.container-conteudo-jogos :nth-child(2) {
  display: grid;
}

.container-conteudo-jogos section .card-video iframe{
  background: transparent;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 280px;
  border-radius: 15px;
}

.container-conteudo-jogos section .link-video {
  align-self: start;
  display: grid;
  grid-template-columns: 7fr 1fr;
  align-items: center;
  justify-items: end;
  justify-self: end;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
}

.container-conteudo-jogos section a {
  padding-right: -10px;
  transition: all 0.3s;
}

.container-conteudo-jogos section a:hover {
  font-weight: 700;
}

@media (max-width: 1144px) {
  .container-conteudo-jogos section .cards .secao-card {
    width: 92%;
  }
  .container-conteudo-jogos section .cards .secao-card h2 {
    font-size: 20px;
  }
  .container-conteudo-jogos section .cards .secao-card a {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  .container-conteudo-jogos section .cards .secao-card {
    padding: 15px;
  }
  .container-conteudo-jogos {
    grid-template-columns: 100%;
  }
}

@media (max-width: 768px) {
  #container-um {
    padding: 0px 40px 50px 40px;
  }
  .container-conteudo-jogos section .secao-texto h1 {
    font-size: 31px;
  }
}

@media (max-width: 668px) {
  #container-jogos-um {
    padding: 0px 40px 50px 40px;
  }
  .container-conteudo-jogos section {
    margin-top: 10px;
  }
  .container-conteudo-jogos section .secao-texto h1 {
    font-size: 28px;
  }
  .container-conteudo-jogos section p {
    font-size: 16px;
    line-height: 20px;
    margin: 6% 0;
  }
  .container-conteudo-jogos section a,
  .container-conteudo-jogos section .button-plus {
    font-size: 16px;
  }
}

@media (max-width: 580px) {
  #container-jogos-um {
    padding: 0px 20px 50px 20px;
  }
  .container-conteudo-jogos section .secao-texto img {
    border-radius: 10px;
    width: 70px;
    height: 70px;
    margin-right: 0;
  }
  .container-conteudo-jogos section .secao-texto h1 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .container-conteudo-jogos section .cards {
    grid-template-columns: 1fr;
  }
  .container-conteudo-jogos section .cards .secao-card {
    margin-bottom: 10px;
  }
  .container-conteudo-jogos section .secao-texto h1 {
    font-size: 24px;
  }
  .container-conteudo-jogos section p {
    font-size: 14px;
    line-height: 20px;
  }
  .container-conteudo-jogos section .cards .secao-card {
    width: 70%;
  }
  .container-conteudo-jogos section a,
  .container-conteudo-jogos section .button-plus {
    font-size: 14px;
  }
}

.wrapper {
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, .5);

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {

  font-size: 14px;
  font-family: 'Raleway', sans-serif;

  background: #C5A7DF;
  border: 2px solid #7A519F;
  border-radius: 8px;

  position: relative;

  text-align: center;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid #7A519F;;
  font-size: 18px;
  text-align: center;
  padding: 5px;

  font-weight: 500;
  padding: 8px 0;
}

.modal .header p {
  margin: 5px 0;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;

  padding: 20px 42px;
}

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;

  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;

  display: flex;
  align-items: center;
  justify-content: center;
}

#container-home{
    margin: 5% 10%;
    font-family: 'Raleway', sans-serif;
    font-family: 'Roboto', sans-serif;
    color: var(--color-text);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#container-home #parte-inicial{
    display: flex;
    align-items: center;
}

#container-home #parte-inicial .introducao{
    width: 50%;
    padding-right: 100px;
    align-items: center;
}


#container-home #parte-inicial .introducao h1{
    font-size: 38px;
    font-weight: bold;
}

#container-home #parte-inicial .introducao p{
    margin: 25px 0;

    font-size: 21px;
    font-weight: 300;

    text-align: justify;
}

#container-home #parte-inicial .introducao button.ler-mais {
    width: 200px;

    background: none;

    border: none;
    border-bottom: 1px solid #62B8D3;

    color: #459DB9;

    margin-top: 10px;
}

#container-home #parte-inicial .introducao .container-button{
    margin-top: 50px;

    width: 280px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--color-button);
    color: var(--color-text-white);

    border: none;
    border-radius: 8px;
    
    font-family: 'Raleway', sans-serif;
    font-size: 18px ;   
    font-weight: 400;
    cursor: pointer;

    text-decoration: none;
}

#container-home #parte-inicial .introducao .container-button:hover{
    background: #BD92E3;
    translate: 0.10s;
}

#container-home #parte-inicial .introducao .container-button img{
    padding-left: 15px;
    width: 45px;
}

#container-home .imagem-inicial{
    width: 50%;
    height: 300px;
    border-radius: 15px;
}

#container-home .imagem-inicial iframe{
    width: 100%;
    border: none;
}

.pagina-home #icons{
    display: flex;
    width: 100%;

    margin-top: 8%;
}

.pagina-home #icons .circulo-redes-social{
    margin: 20px 0;

    width: 50px;
    height: 50px;  
    
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px #E0E0E0;

    border-radius: 50%;
    background-color:var(--color-text-white);

    margin-right: 30px;
}

.pagina-home #icons .circulo-redes-social .icon{
    width: 25px;
}

@media (max-width: 800px){
    #container-home #parte-inicial{
        flex-direction: column-reverse;
    }

    #container-home #parte-inicial .introducao{
        margin-top: 20px;
        width: 100%;
        padding-right:0;

        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }

    #container-home #parte-inicial .introducao .container-button {
        margin-top: 10px;
        width: 180px;
        font-size: 15px ;
    }

    #container-home #parte-inicial .introducao h1{
        font-size: 28px;
    }

    #container-home #parte-inicial .introducao p{
        margin: 10px 0;
    
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }

    #container-home .imagem-inicial{
        width: 100%;
    }

    .pagina-home #icons{
        align-items: center;
        justify-content: center;
    }

    .pagina-home #icons .circulo-redes-social:last-child{
        margin-right: 10px;
    }

    .pagina-home #icons .circulo-redes-social .icon{
        width: 20px;
    }

}
#container{
    /* background: var(--color-background);     */
    font-family: 'Raleway', sans-serif;
    color: var(--color-text);
}

.container-ebook{
    margin: 0 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.container-ebook .secao-texto{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
}

.container-ebook .secao-texto h2{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 32px;

    margin-bottom: 10px;
}

.container-ebook .secao-texto p{
    margin: 0 0 5px 0;
    margin-right: 10%;

    font-weight: 400;
    font-size: 18px;
    line-height: 28px;

    text-align: justify;
}

.container-ebook .secao-texto button.ler-mais {
    width: 200px;

    background: none;

    border: none;
    border-bottom: 1px solid #62B8D3;

    color: #459DB9;

    margin-top: 10px;
}

.container-ebook .secao-texto .container-button button{
    margin-top: 50px;
    width: 52%;
    padding: 8px 5%;
    background: var(--color-button);
    text-align: justify;

    border-radius: 8px;
    transition: all .3s;
    cursor: pointer;
}

.container-ebook .secao-texto .container-button button:hover{
    border-color: #BD92E3;
    background: #BD92E3;
    translate: 0.10s;
}

.container-ebook .secao-texto .container-button button{
    display: flex;
    align-items: center;
    justify-content: center;
    
    text-decoration: none;
    color: var(--color-text-white);
    font-size: 16px ;   
    font-weight: 300;

    text-align: center;
}

.container-ebook .secao-texto .container-button button img{
    width: 25px;
    height: 20px;
    margin-left: 15px;
}

.container-ebook .secao-video{
    width: 50%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

iframe{
    height: 300px;
    border-radius: 15px;
}

.container-ebook .secao-video iframe{
    width: 85%;
    border: none;
}

@media (max-width: 800px){
    .container-ebook .secao-video iframe{
        width: 100%;

        margin-bottom: 20px;
    }

    .container-ebook{
        flex-direction: column-reverse;
    }

    .container-ebook .secao-texto{
        width: 100%;
        display: flex;
        align-items: center;
        justify-items: center;
    }

    .container-ebook .secao-video{
        width: 100%;
    }

    .container-ebook .secao-texto h1{
        margin: 5% 0;
    }

    .container-ebook .secao-texto p{
        margin: 0;
        font-size: 16px;
    }

    .container-ebook .secao-texto .container-button { 
        width: 100%; 
    }

    .container-ebook .secao-texto .container-button button {
        margin-left: 3%;
        width: 90%;
        font-size: 14px ;
    }
}
#container{
    padding: 0px 100px 50px 100px;
}

.container-projeto{
   display: grid;
   width: 100%;
   grid-template-columns: 60% 40%;
}

.container-projeto .secao-texto{
    justify-self: center;
}

.container-projeto .secao-texto h1{
    width: 100%;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-weight: bold;
    font-size: 32px;

    margin-right: 15%;
}

.container-projeto .secao-texto p{
    margin: 4% 0;
    margin-right: 15%;
    
    text-align: justify;

    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

.container-projeto .secao-texto button{
    width: 200px;
    
    background: none;

    border: none;
    border-bottom: 1px solid #62B8D3;

    color: #459DB9;
}

.container-projeto .secao-imagem{
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
}

.container-projeto .secao-imagem img{
  margin: 15px;
  width: 90%;   

  border-radius: 10px;
}

.container-projeto .secao-imagem :last-child{
    width: 190%;
}

.container-projeto .container-button{
    margin-top: 50px;

    width: 260px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--color-button);
    color: var(--color-text-white);

    border: none;
    border-radius: 8px;
    
    font-family: 'Raleway', sans-serif;
    font-size: 17px ;   
    font-weight: 400;
    cursor: pointer;

    text-decoration: none;
}

.container-projeto .container-button:hover{
    background: #BD92E3;
    translate: 0.10s;
}

.container-projeto .container-button img{
    padding-left: 15px;
    width: 45px;
}


@media only screen and (max-width: 1024px){
    #container{
        padding: 0px 80px 50px 80px;
    }
    .container-projeto{
        grid-auto-columns: 40% 60%;
    }

    .container-projeto .secao-texto p{
        margin-right: 8%;
    }
    .container-projeto .secao-texto h1{
        font-size: 32px;
    }
    .container-projeto .secao-texto p{
        font-size: 16px;
    }
}
@media only screen and (max-width: 850px){
    #container{
        padding: 0px 80px 50px 80px;
    }

    .container-projeto{
        grid-template-columns: 1fr;
    }

    .container-projeto .secao-texto p{
        justify-self: center;
        font-size: 16px;
        margin: 5% 0;
        margin-right: 0;
    }

    .container-projeto .secao-imagem{
        justify-self: start;

    
    }
}

@media only screen and (max-width: 687px){
    #container{
        margin-top: 30px;
    }

    .container-projeto .secao-texto h1{
        font-size: 28px;
    }

}

@media only screen and (max-width: 500px){
    #container{
        padding: 0px 30px 50px 30px;
    }
    .container-projeto .secao-texto p{
        font-size: 14px;
    }
    .container-projeto .secao-imagem{
        grid-template-columns: 1fr;
    }

    .container-projeto .secao-imagem :last-child{
        width: 90%;
    }
}

@media only screen and (max-width: 340px){
    .container-projeto .secao-texto p{
        font-size: 12px;
    }
}

#container-artigos{
    /* background: var(--color-background);     */

    font-family: 'Raleway', sans-serif;
    color: var(--color-text);
    padding: 0px 100px 50px 100px;
}

.pagina-artigos{
    display: flex;
    flex-direction: column;
}

.pagina-artigos h1{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

.pagina-artigos .bloco-artigos{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.pagina-artigos .bloco-artigos .artigo{ 
    cursor: pointer;
    
    margin-top: 30px;
    width: 360px ;
    margin-right: 20px;
    padding: 1.2% 2%;

    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 8px;
}

.pagina-artigos .bloco-artigos .artigo .artigo-content {
    text-decoration: none;
    color: #252525;
}

.pagina-artigos .bloco-artigos .artigo .artigo-content p {
    padding-top: 8px;
    color: #444444;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.pagina-artigos .bloco-artigos .artigo .artigo-content h3{
    font-size: 20px;
}

@media (max-width: 800px){
    .pagina-artigos .bloco-artigos .artigo .artigo-content h3{
        font-size: 20px;
    }

    .pagina-artigos .bloco-artigos .artigo .artigo-content p{
        font-size: 15px;
        line-height: 20px;
    }

}
#container-artigos{
    /* background: var(--color-background);     */

    font-family: 'Raleway', sans-serif;
    color: var(--color-text);
    padding: 0px 100px 50px 100px;
}

.pagina-artigos{
    display: flex;
    flex-direction: column;
}

.pagina-artigos h2{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 32px;
}

.pagina-artigos .titulo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.pagina-artigos p{
    text-align: justify;
    margin-top: 2%;
    font-size: 18px;
    line-height: 30px;
}

button{
    display:grid;
    align-items: center;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    cursor: pointer;
 
    font-family: 'Raleway', sans-serif;
    color:#7A519F;
    font-size: 16px;
 
    background: #C5A7DF;
    padding: 3px 10px;
    border: 4px solid #7A519F;
    border-radius: 8px;
}

@media (max-width: 800px){
    #container-artigos{
        padding: 0px 50px 50px 50px;
    }
    .pagina-artigos h1{
        margin-top: 10%;
    }

    .pagina-artigos .bloco-artigos .artigo a p{
        font-size: 15px;
        line-height: 20px;
    }

    .pagina-artigos .titulo{
        flex-direction: column;
    }
    
    button{
        margin: 20px 0;
        font-size: 14px;
        padding: 3px 8px;
     }

}

@media (max-width: 580px){
    .pagina-artigos p {
        font-size: 14px;
    }
}
#container-videos-um{
   padding: 0px 100px 20px 100px;
}

.container-conteudo-videos{
   display: grid;
   width: 97.5%;

}
.container-conteudo-videos .secao-texto{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 40px;
 }

 .container-conteudo-videos .secao-texto h1{
   font-family: 'Poppins', sans-serif;
   font-weight: bold;
   font-size: 28px;
   color: var( --color-text);
 }

 .container-conteudo-videos .secao-texto button{
   display:grid;
   align-items: center;
   grid-template-columns: auto auto;
   grid-gap: 10px;
   cursor: pointer;

   font-family: 'Raleway', sans-serif;
   color:#7A519F;
   font-size: 16px;

   background: #C5A7DF;
   padding: 3px 10px;
   border: 4px solid #7A519F;
   border-radius: 8px;
 }

.container-conteudo-videos section{
   display: grid;
   justify-self: center;
}

#video {
   width: 620px;
   height: 330px;
}

@media(max-width: 768px){
   #container-videos-um{
      padding: 0px 40px 20px 40px;
   }
  
   .container-conteudo-videos .secao-texto h1{
      font-size: 28px;
   }

}

@media(max-width: 668px){
   .container-conteudo-videos .secao-texto h1{
      font-size: 26px;
   }
   .container-conteudo-videos .secao-texto button{

      font-size: 14px;
  
      padding: 3px 8px;
   }
   .container-conteudo-videos{
      display: grid;
      width: 100%;
   }
}
@media(max-width: 700px) {
   #video {
      width: 580px;
      height: 280px;
   }
}
@media(max-width: 633px){
   .container-conteudo-videos .secao-texto h1{
      font-size: 26px;
   }
   #video {
      width: 400px;
      height: 280px;
   }

}
@media(max-width: 480px){
   #video {
      width: 300px;
      height: 280px;
   }
   .container-conteudo-videos .secao-texto h1{
      font-size: 24px;
      margin-right: 20px;
   }
   .container-conteudo-videos .secao-texto button{
      font-size: 13px;
      border: 3.5px solid #7A519F;
      padding: 3px 7px;
   }

}
#container{
    /* background: var(--color-background);     */

    font-family: 'Raleway', sans-serif;
    color: var(--color-text);
    padding: 0px 5% 50px 5%;
}

.pagina-videos{
    display: flex;
    flex-direction: column;
}

.pagina-videos h1{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

.pagina-videos h2{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    margin-top: 2%;
    font-size: 25px;

    min-width: 90vw;
}

.pagina-videos #container-videos{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
}

.pagina-videos #container-videos iframe {
    border: none;
}

.pagina-videos #container-videos  .thumbnail {
    margin-top: 40px;
    margin-bottom: 40px;
    cursor: pointer;
    width: 320px;
    height: 180px;
}

.pagina-videos #container-videos .thumbnail p {
    margin-top: 5px;
    color: #171717;
    text-align: justify;
    font-family: 'Segoe UI', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.pagina-videos #container-videos .thumbnail iframe {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pagina-videos #faixa{
    width: 100%;
    height: 3px;
    background: #76CDA0;
    margin-top: 6%;
    margin-bottom: 2%;
}

@media (max-width: 1110px){
    .pagina-videos #container-videos{
        padding: 0;
        grid-template-columns: repeat(2, 1fr);
        align-content: center;
    }
}

@media (max-width: 775px){
    .pagina-videos{
        align-items: center;
    }
    .pagina-videos #container-videos{
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
    }

}

.title-autores {
    margin-left: 10%;
    margin-bottom: 30px;
}

.title-autores h3 {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-weight: bold;
    font-size: 30px;
}

#autores {
    display: flex;
    flex-direction: column;

    padding: 0 10% 50px 10%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;

    line-height: 1.7em;
}

#autores div:first-child {
    display:grid;
    grid-template-columns: 200px calc(100% - 200px);
}

#autores div p {
    margin-left: 20px;
    text-align: justify;
    font-size: 17px;
    letter-spacing: 0.02em;

    display: flex;
    flex-direction: column;
}

#autores div img {
    width: 200px;
    height: 240px;

    object-fit: cover;
}

#autores div:nth-child(2) {
    margin-top: 40px;

    display:grid;
    grid-template-columns: calc(100% - 200px) 200px ;
}

#autores div:nth-child(2) p {
    margin-left: 0;
    margin-right: 20px;
}

#autores div:nth-child(2) img {
    justify-self: end;
}

@media (max-width: 690px){
    .title-autores {
        margin: 20px 0;
    }
    .title-autores h3 {
        text-align: center;
    }

    #autores div p p {
        display: flex;
        flex-wrap: wrap;
    }
    #autores div:first-child {
        grid-template-columns: 1fr;
        grid-template-rows: 40% 60%;
    }
    #autores div:first-child img {
        
        align-self: center;
        justify-self: center;
    }
    #autores div:nth-child(2) {
        margin-top: 50px;
        grid-template-columns: 1fr;
        grid-template-rows: 60% 40%;
    }

    #autores div:nth-child(2) img {
        grid-row-start: 1;
        grid-row-end: 2;
        align-self: center;
        justify-self: center;
    }

    #autores div:nth-child(2) p {
        grid-row-start: 2;
        grid-row-end: 3;

        margin-bottom: 30px;
    }
}

@media (max-width: 560px){
    #autores div:nth-child(2) p {
        margin-bottom: 100px;
    }
    .title-autores {
        margin: 20px 0 0;
    }
    .title-autores h3 {
        text-align: center;
    }
}

@media (max-width: 470px){
    #autores div:nth-child(2) p {
        margin-bottom: 280px;
    }
}



#main-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
}

#main-footer img {
    width: 30px;
    margin-bottom: 10px;
}

#main-footer p:nth-child(2) {
    margin: 5px 0;
}

#main-footer p {
    color: var(--color-text);
    font-family: 'Raleway', sans-serif;
    font-size: 12px;

    letter-spacing: 0.05em;
}

#main-footer p a {
    margin: 0 .5em;
    text-decoration: none;
    font-weight: bold;
    color: var(--color-text);
}
