@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;100&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;


    --color-background: #F9F9F9;
    --color-text: #252525;
    --color-button: #7A519F;
    --color-text-white: #FFFFFF;
    --color-nav:  #4BBF83;
}

body{
    background: var(--color-background); 
    overflow-x: hidden;
    position: relative;
}

body::after {
    content: '';
    background: url(./assets/background/BG.png) no-repeat;
    background-size: 80% auto;
    background-position: top left;
    opacity: .7;
    transform: rotate(8deg);
    position: fixed;
    top: -80px;
    left: -300px;
    z-index: -5;
    width: 100vw;
    height: max(100%, 100vh);
}

.loading {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1160px){
    body::after {
        background-size: 100% 100%;
    }
}

@media (max-width: 940px){
    body::after {
        background-size: 100% auto;
    }
}

@media (max-width: 880px){
    body::after {
        top: -40px;
        left: -120px;
    }
}

@media (max-width: 620px){
    body::after {
        top: -20px;
        left: -80px;
        background-size: 100vw auto;
    }
}

/* -- scroll -- */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: var(--color-nav);
    border-radius: 8px;
}


